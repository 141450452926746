import React from "react";
import logo from "../pics/Logo/Logo.png";
import WhatsAppButton from "../tools/WhatsAppButton";
import CallButton from "../tools/CallButton";
import { useLocation, useNavigate } from "react-router-dom";
import phoneIcon from "../pics/Buttons/call-calling.png";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname === "/") {
    
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate("/");
    }
  };

  return (
    <nav className="bg-white glass fixed top-0 left-0 right-0 z-20">
       <div className="max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center w-full justify-between px-4">
            <CallButton
              children={
                <img src={phoneIcon} alt="Phone" className="h-12 w-12" />
              }
            />
            <img
              onClick={handleClick}
              src={logo}
              alt="Logo"
              className="h-16 ml-2"
            />
            <WhatsAppButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
