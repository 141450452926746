import React from "react";
import b1 from "../pics/Brands/b1.png";
import b2 from "../pics/Brands/b2.png";
import b3 from "../pics/Brands/b3.png";
import b4 from "../pics/Brands/b4.png";
import b5 from "../pics/Brands/b5.png";
import b6 from "../pics/Brands/b6.png";
const Brands = () => {
  const images = [b1, b2, b3, b4, b5, b6];
  return (
<div className="flex  justify-center items-center">
  {images.map((img, index) => (
    <img
      key={index}
      src={img}
      alt={`b${index + 1}`}
      className="w-1/6 max-w-xs h-auto object-contain"
    />
  ))}
</div>



  );
};

export default Brands;
