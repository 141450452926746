import React from "react";
import bg1 from "../pics/Others/tamiir.png";
import Tamirat from "./Tamirat";
import Lavazem from "./Lavazem";
import Sharjer from "./Sharjer";
import Batteries from "./Batteries";
import Girekamari from "./GireKamari";
import Anten from "./Anten";
import Headphone from "./Headphone";
import SabtDarkhast from "./SabtDarkhast";
import Footer from "./Footer";
import CallButton from "../tools/CallButton";
import Brands from "./Brands";
import WhyNovin from "./WhyNovin";
import iconcall from "../pics/Buttons/Iconcall.png";

const Body = () => {
  return (
    <>
      <div className="relative bg-custom-gray w-full flex flex-col justify-center px-5">
        <div className="relative flex justify-center items-center">
          <img src={bg1} alt="Background" className="w-full my-10" />
          <div className="absolute bottom-4 right-4 md:right-8 lg:right-12">
            <CallButton>
              <div
                dir="rtl"
                className="h-9 mb-4 lg:mb-6 rounded-lg flex items-center justify-center bg-[rgba(0,82,204,1)] shadow-custom"
              >
                <div className=" flex px-2 items-center gap-2">
                  <img src={iconcall} alt="phone" className="w-6 h-6 p-1" />
                  <button className="text-gray-200 font-light">
                    تماس با ما
                  </button>
                </div>
              </div>
            </CallButton>
          </div>
        </div>
        <Tamirat />

        <Lavazem />
      </div>
      <div className="relative w-full flex flex-col justify-center">
        <Sharjer />
      </div>
      <div className="relative w-full flex flex-col justify-center px-10">
        <Batteries />
      </div>
      <Girekamari />
      <div className="relative bg-custom-gray w-full flex flex-col justify-center px-10">
        <Anten />
      </div>
      <div>
        <Headphone />
        <Brands />
        <WhyNovin />
      </div>
      <SabtDarkhast />
      <Footer />
    </>
  );
};

export default Body;
