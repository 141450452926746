import React from "react";
import bg6 from "../pics/Others/bg-6.png";
import loc from "../pics/Others/loc.png";
import tel from "../pics/Others/tel.png";
import logo from "../pics/Logo/Logo2.png";
const Footer = () => {
  return (
    <div className="relative w-full">
      <div className="relative">
        <img
          src={bg6}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />

        <div className="relative z-10 flex flex-col items-center justify-center p-6">
          <img src={logo} alt="Logo" className="h-15 mb-4" />

          <div
            id="1"
            className="flex flex-col left-5 right-5 rounded-lg bg-white bg-opacity-30 p-4 mb-4"
          >
            <p dir="rtl" className="text-white text-lg font-bold">
              درباره ما
            </p>
            <p dir="rtl" className="text-white mt-2">
              نوین ارتباط همیشه همراه شماست، تیمی از تعمیرکاران و متخصصین با
              تجربه که آماده خدمت رسانی به شماست. ما با استفاده از تجربه و ابزار
              های پیشرفته ی خود، کنار شما هستیم تا با تعهد به کیفیت و خدمات فوری
              در محل، از حرفه ای بودن ما مطمئنن شوید و به تجربه متفاوت با ما
              اعتماد کنید.
            </p>
          </div>

          <div
            id="2"
            className="flex flex-col left-5 right-5 rounded-lg bg-white bg-opacity-30 p-4"
            style={{ width: "calc(100%)" }}
          >
            <p dir="rtl" className="text-white text-lg font-bold">
              تماس با ما
            </p>
            <div className="relative flex flex-row-reverse mt-2">
              <img src={loc} alt="loc" className="h-5 w-5 m-1" />
              <p dir="rtl" className="text-white">
                آدرس: تهران، خیابان انقلاب
              </p>
            </div>
            <div className="relative flex flex-row-reverse mt-2">
              <img src={tel} alt="tel" className="h-5 w-5 m-1" />
              <p dir="rtl" className="text-white">
                ۰۹۹۰۹۲۳۷۰۰۶ مقدم
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-custom-blue-1">
        <p dir="rtl" className="py-3 text-sm text-white opacity-80">© ۱۴۰۳ - تمامی حقوق برای نوین ارتباط محفوظ است.</p>
      </div>
    </div>
  );
};

export default Footer;
