import Body from "../components/Body";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ScrollToTopButton from "../tools/ScrollToTopButton";

function Home() {
  return (
    <div className="mx-auto bg-custom-gray max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
      <Navbar />
      <Header />
      <Body />
      <ScrollToTopButton />
    </div>
  );
}

export default Home;
