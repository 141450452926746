import React from "react";
import line1 from "../pics/Others/line1.png";
import b1 from "../pics/Bisims/b1.png";
import b2 from "../pics/Bisims/b2.jpg";
import b3 from "../pics/Bisims/b3.png";
import b4 from "../pics/Bisims/b4.png";
import b5 from "../pics/Bisims/b5.webp";
import b6 from "../pics/Bisims/b6.png";
import b7 from "../pics/Bisims/b7.png";
import b8 from "../pics/Bisims/b8.png";
import b9 from "../pics/Bisims/b9.jpg";
import b10 from "../pics/Bisims/b10.jpg";
import b11 from "../pics/Bisims/b11.jpg";
import b12 from "../pics/Bisims/b12.jpg";
import bg22 from "../pics/Others/bg-2-2.png";
import ScrollButton from "../tools/ScrollButton";

const Tamirat = () => {
  const images1 = [
    { src: b2, text: "موتورولا MT666" },
    { src: b3, text: "کنوود 3307" },
    { src: b4, text: "باووفنگ MT888s" },
    { src: b1, text: "موتوکام MC555" },
  ];
  const images2 = [
    { src: b5, text: "طنین GP800" },
    { src: b6, text: "کنوود G3207" },
    { src: b7, text: "طنین GP23" },
    { src: b8, text: "موتوکام MC444" },
  ];

  const images3 = [
    { src: b9, text: "موتورولا MT777" },
    { src: b11, text: "کاووش T816" },
    { src: b12, text: "کنوود new3207" },
    { src: b10, text: "باووفنگ UV82" },
  ];

  const renderImages = (images) => (
    <div className="overflow-x-auto rtl" style={{ direction: "rtl" }}>
      <div
        className="flex gap-5 justify-start"
        style={{ minWidth: "calc(25% * 6)" }}
      >
        {images.map((img, index) => (
          <div
            key={index}
            className="flex-shrink-0 shadow-xl mr-3 rounded-lg bg-white my-10 w-1/3 md:w-1/3"
          >
            <img
              src={img.src}
              alt={`b${index + 1}`}
              className="py-3 bg-white rounded-lg  object-contain"
              style={{ height: "200px", width: "100%" }}
            />
            <p className="text-center  mb-2  text-sm">{img.text}</p>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="relative bg-custom-gray">
      <h2
        dir="rtl"
        className="absolute right-1 transform text-2xl font-bold text-black"
      >
        تعمیرات
      </h2>
      <img src={line1} alt="line" className="w-full mt-2" />
      {renderImages(images1)}
      {renderImages(images2)}
      {renderImages(images3)}
      <div className="relative flex my-10  flex-col justify-center items-center">
        <div className="relative  w-full">
          <img
            src={bg22}
            alt="Background"
            className="w-full bg-custom-radial2 rounded-2xl object-cover h-64"
          />
          <div
            id="main1"
            className="absolute bg-custom-radial2 rounded-2xl inset-0 flex flex-col items-center justify-center "
          >
            <h3 className="bg-blue-700 text-white px-5 py-2 rounded-lg shadow-2xl mb-4 text-base md:text-lg lg:text-xl">
              نیاز به مشاوره دارید؟
            </h3>
            <div className="flex flex-col gap-2 bg-white rounded-lg p-4 w-11/12 shadow-2xl">
              <h4 className="font-bold text-sm md:text-base lg:text-lg text-blue-900 text-center">
                مشکل دستگاه خود را برای ما بنویسید
              </h4>
              <h4 className="text-xs md:text-sm lg:text-base text-blue-500 text-center">
                تا همکاران ما پس از عیب یابی با شما تماس بگیرند
              </h4>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 transform translate-y-1/2 h-10">
          <ScrollButton
            children={
              <button
                className={` bg-[rgba(0,82,204,1)] rounded-lg font-light h-10 text-gray-200 py-4 px-2 flex items-center justify-center 
                
                  
              `}
                alt="button"
              >
                ثبت درخواست
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Tamirat;
