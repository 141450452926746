import React from "react";
import whatsappIcon from "../pics/Buttons/whatsapp.png";

const WhatsAppButton = () => {
  const phoneNumber = "+989909237006";
  const whatsappURL = `https://wa.me/${phoneNumber}`;

  return (
    <button onClick={() => (window.location.href = whatsappURL)}>
      <img src={whatsappIcon} alt="Whatsapp" className="h-12 w-12" />
    </button>
  );
};

export default WhatsAppButton;
