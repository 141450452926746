import error from "../pics/Others/Error.png";
import bazgasht from "../pics/Buttons/bazgasht.png";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div className="flex flex-col gap-5 pb-10 items-center justify-center min-h-screen bg-custom-radial max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto">
      <img src={error} alt="error-pic" className="" />
      <p className=" text-4xl text-white text-center">
        !مشکلی پیش آمده است
      </p>
      <p className="text-center text-white text-xl">
        متاسفانه صفحه مورد نظر یافت نشد
      </p>
      <Link to="/" className="mt-4">
        <img src={bazgasht} alt="Bazgasht-btn" />
      </Link>
    </div>
  );
}

export default NotFound;
