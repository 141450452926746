import React from "react";

const CallButton = ({children}) => {
  const phoneNumber = "+989909237006";
  const callURL = `tel:${phoneNumber}`;

  return (
    <div className="cursor-pointer" onClick={() => (window.location.href = callURL)}>
      {children}
    </div>
  );
};

export default CallButton;
