import React from "react";

const ScrollButton = ({ children }) => {
  const handleScroll = () => {
    const section = document.getElementById("sabt");
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return <div onClick={handleScroll}>{children}</div>;
};

export default ScrollButton;
