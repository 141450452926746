import React, { useState } from "react";
import image1 from "../pics/Sharjers/i1.png";
import image2 from "../pics/Sharjers/i2.png";
import image3 from "../pics/Sharjers/i3.png";
import image4 from "../pics/Sharjers/i4.png";
import image5 from "../pics/Sharjers/i5.png";


const Sharjer = () => {
  const [currentImage, setCurrentImage] = useState(image1);
  const thumbnails = [image1, image2, image3, image4, image5];

  return (
    <div className="flex flex-col bg-custom-gradient p-4 items-center">
      <h2 className="text-white font-bold text-2xl mb-4" dir="rtl">
        شارژر
      </h2>
      <div className="bg-transparent p-4 rounded-lg flex flex-col items-center">
        <img
          src={currentImage}
          alt="Main"
          className="w-full max-w-md rounded-lg bg-white mb-4"
        />
        <div className="flex justify-center space-x-2">
          {thumbnails.map((img, index) => (
            <button key={index} onClick={() => setCurrentImage(img)}>
              <img
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className={`w-16 h-16 bg-white rounded-md object-cover ${
                  currentImage === img ? "brightness-75" : ""
                }`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};


export default Sharjer;
