import React from "react";
import iconcall from "../pics/Buttons/Iconcall.png";
import bisim1 from "../pics/Others/Rectangle 12.png";
import bisim2 from "../pics/Others/a2.png";
import bg1 from "../pics/Others/bg-1.png";
import CallButton from "../tools/CallButton";

const Header = () => {
  return (
    <>
    
        <div className="relative ">
          <img src={bg1} alt="Background" className="w-full pt-20" />
          <div className="absolute pt-24 inset-0 gap-2 lg:gap-10 lg:mt-10 flex flex-col items-center justify-start">
          <h1 className="text-white lg:text-4xl text-2xl font-bold">
              مرجع تخصصی تعمیر و فروش
            </h1>
            <h1 className="text-white lg:text-3xl text-xl font-bold">
              انواع بی سیم و لوازم جانبی
            </h1>
            <div className="cursor-pointer mt-3 ">
              <CallButton
                children={
                  <>
                    <div dir="rtl" className="h-8 p-1 px-2 rounded-lg gap-2 bg-white bg-opacity-20  justify-center p- items-center flex shadow-custom">
                      <img src={iconcall} alt="phone" className="w-6 h-6 p-1"/>
                      <p className=" text-white text-sm font- ">تماس با ما</p>
                    </div>
                  </>
                }
              />
            </div>
            <div className="flex flex-row justify-between items-center z-10 gap-5">
              <div className="relative">
                <img src={bisim1} alt="Phone" className="h-32 shadow-custom rounded-xl opacity-95" />
                <div className="absolute ml-16 mb-2 inset-0 flex flex-col justify-center items-center gap-2">
                  <h2 className="text-white text-xl font-bold">فروش</h2>
                  <h2 className="text-white  font-bold">لوازم جانبی</h2>
                  <h2 className="text-white  ">بی سیم</h2>
                </div>
              </div>
              <div className="relative">
                <img src={bisim2} alt="Phone" className="h-32 shadow-custom rounded-xl opacity-95" />
                <div className="absolute ml-20 mb-2 inset-0 flex flex-col justify-center items-center gap-2">
                  <h2 className="text-white text-xl font-bold">تعمیر</h2>
                  <h2 className="text-white  font-bold">تخصصی</h2>
                  <h2 className="text-white  ">بی سیم</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default Header;
