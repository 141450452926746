import React from "react";
import bg7 from "../pics/Others/bg-7.png";
import bg8 from "../pics/Others/ratio.png";
import l1 from "../pics/WhyNovin/l1.png";
import l2 from "../pics/WhyNovin/l2.png";
import l3 from "../pics/WhyNovin/l3.png";
import l4 from "../pics/WhyNovin/l4.png";
import l5 from "../pics/WhyNovin/l5.png";
import l6 from "../pics/WhyNovin/l6.png";
import l7 from "../pics/WhyNovin/l7.png";
import l8 from "../pics/WhyNovin/l8.png";

const WhyNovin = () => {
  const images = [
    { src: l1, text: "تضمین کیفیت و اصالت" },
    { src: l2, text: "بازگشت وجه" },
    { src: l3, text: "پرداخت در محل" },
    { src: l4, text: "تضمین قیمت محصولات" },
    { src: l5, text: "خدمات در محل" },
    { src: l6, text: "تعمیر فوری" },
    { src: l7, text: "پشتیبانی همه روزه" },
    { src: l8, text: "ارسال سریع" },
  ];

  return (
    <div>
      <div id="main1" className="relative">
        <img src={bg7} alt="bg" className="w-full h-auto" />
        <img
          src={bg8}
          alt="bg"
          className="absolute top-0 left-0 w-full h-auto opacity-20"
        />

        <div
          id="main2"
          className="absolute inset-0 flex flex-col justify-center items-center"
        >
          <p
            dir="rtl"
            className="text-white self-end p-3 mb-2 text-lg font-bold"
          >
            چرا نوین ارتباط
          </p>
          <div className="grid grid-cols-4 mx-5 gap-4 mb-5">
            {images.map((img, index) => (
              <div key={index} className="flex flex-col gap-5 items-center">
                <div className="w-16 h-16 overflow-hidden">
                  <img
                    src={img.src}
                    alt={`b${index + 1}`}
                    className="w-full h-full p-3 shadow-lg rounded-2xl bg-white bg-opacity-20  object-contain"
                  />
                </div>
                <p className="text-center text-sm text-white">{img.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyNovin;
