import React, { useState, useEffect } from "react";
import btn from "../pics/Buttons/up.png";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 100) {
      if (!isVisible) {
        setIsTransitioning(true);
        setIsVisible(true);
      }
    } else {
      if (isVisible) {
        setIsTransitioning(false);
        setTimeout(() => setIsVisible(false), 100); 
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, [isVisible]);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-10 right-10   transition-opacity duration-300 ease-in-out ${
        isVisible ? "opacity-100" : "opacity-0"
      } ${isTransitioning ? "opacity-100" : ""}`}
      style={{ transition: "opacity 0.3s ease-in-out", zIndex: 1000 }}
    >
      <img src={btn} alt="top" className="w-12" />
    </button>
  );
};

export default ScrollToTopButton;
