import React from "react";
import line1 from "../pics/Others/line1.png";
import bg1 from "../pics/Others/bg-3.png";
import button1 from "../pics/Buttons/b-5.png";
import ScrollButton from "../tools/ScrollButton";
const Lavazem = () => {
  return (
    <div className="relative">
      <h2
        dir="rtl"
        className="absolute right-1 transform text-2xl font-bold text-black"
      >
        لوازم جانبی
      </h2>
      <img src={line1} alt="line" className="w-full mt-2" />
      <div className="relative flex justify-center items-center">
        <img src={bg1} alt="Background" className="w-full my-10 mt-5" />
        <div className="absolute bottom-6 md:bottom-8 left-10">
          <ScrollButton children={<img src={button1} alt="button" />} />
        </div>
      </div>
    </div>
  );
};

export default Lavazem;
