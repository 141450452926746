import React, { useState } from "react";
import LoaderDots from "../tools/LoaderDots";
import axios from "axios";
import CheckBox from "../tools/CheckBox";
import NoCheckBox from "../tools/NoCheckBox";

const SabtDarkhast = () => {
  const [username, setUsername] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numberIsWrong, setNumberIsWrong] = useState(false);
  const [usernameIsWrong, setUsernameIsWrong] = useState(false);
  const [hasTouchedMessage, setHasTouchedMessage] = useState(false);
  const [isCheckBoxVisible, setIsCheckBoxVisible] = useState(false);
  const [isNoCheckBoxVisible, setNoIsCheckBoxVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post("/submit", {
        username,
        number,
        message,
      });

      setIsCheckBoxVisible(true);

      setTimeout(() => {
        setIsCheckBoxVisible(false);
      }, 5000);
    } catch (error) {
      console.error("There was an error!", error);
      setNoIsCheckBoxVisible(true);
      setTimeout(() => {
        setNoIsCheckBoxVisible(false);
      }, 5000);
    } finally {
      setNumber("");
      setMessage("");
      setUsername("");
      setLoading(false);
    }
  };

  const regex = /((0?9)|(\+?989))\d{9}/g;

  const validatePhoneNumber = (value) => {
    const isValid = regex.test(value);
    setNumberIsWrong(!isValid);
    return isValid;
  };

  const handleNumberBlur = () => {
    const isValid = validatePhoneNumber(number);
    setDisable(!isValid || usernameIsWrong || !message || loading);
  };

  const handleUsernameBlur = () => {
    setUsernameIsWrong(username.trim() === "");
    setDisable(
      !validatePhoneNumber(number) || usernameIsWrong || !message || loading
    );
  };

  const handleNumberInput = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length > 11) {
      e.target.value = inputValue.slice(0, 11);
    }

    setNumber(inputValue);
    setDisable(
      !validatePhoneNumber(inputValue) || usernameIsWrong || !message || loading
    );
  };

  const handleUsernameInput = (e) => {
    const inputValue = e.target.value;
    setUsername(inputValue);
    setUsernameIsWrong(inputValue.trim() === "");
    setDisable(
      !validatePhoneNumber(number) || usernameIsWrong || !message || loading
    );
  };

  const handleMessageChange = (e) => {
    const inputValue = e.target.value;
    setMessage(inputValue);
    setHasTouchedMessage(true);
    setDisable(
      !validatePhoneNumber(number) ||
        usernameIsWrong ||
        inputValue.trim() === "" ||
        loading
    );
  };

  const handleMessageBlur = () => {
    setHasTouchedMessage(true);
  };

  return (
    <div id="sabt" className="py-10 bg-custom-gray ">
      {isCheckBoxVisible ? (
        <CheckBox isVisible={isCheckBoxVisible} />
      ) : isNoCheckBoxVisible ? (
        <NoCheckBox isVisible={isNoCheckBoxVisible} />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="border-2 shadow-xl bg-white border-custom-blue mx-5 rounded-2xl"
        >
          <div className="flex  flex-col justify-between items-center gap-7">
            <h3
              dir="rtl"
              className="text-custom-blue p-4 pt-4 font-bold self-end"
            >
              ثبت درخواست تعمیر و مشاوره
            </h3>
            <div className="relative w-72">
              <input
                type="text"
                dir="rtl"
                id="floating_outlined1"
                className={`block px-2.5 pb-2.5 pt-4 w-full text-sm border-2 text-custom-blue font-bold bg-transparent rounded-lg border-custom-gray appearance-none focus:outline-none focus:ring-0 focus:border-custom-blue peer ${
                  usernameIsWrong ? "border-red-500" : "border-gray-300"
                }`}
                placeholder=" "
                value={username}
                onChange={handleUsernameInput}
                onBlur={handleUsernameBlur}
              />
              <label
                htmlFor="floating_outlined1"
                dir="rtl"
                className="absolute py-1 bg-white text-sm text-custom-gray-text font-bold duration-300 transform -translate-y-7 scale-75 top-2 z-10 origin-[0] bg-transparent px-2 peer-focus:px-2 peer-focus:text-custom-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:scale-75 peer-focus:-translate-y-6 rounded-lg rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                نام و نام خانوادگی
              </label>
            </div>
            <div className="relative w-72">
              <input
                type="number"
                dir="rtl"
                id="floating_outlined2"
                className={`block px-2.5 pb-2.5 pt-4  w-full text-sm border-2 text-custom-blue font-bold bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-custom-blue peer ${
                  numberIsWrong ? "border-red-500" : "border-gray-300"
                }`}
                placeholder=" "
                value={number}
                onChange={handleNumberInput}
                onBlur={handleNumberBlur}
                inputMode="numeric"
                pattern="\d{1,11}"
                min="0"
              />
              <label
                htmlFor="floating_outlined2"
                dir="rtl"
                className="absolute rounded-lg py-1 bg-white text-sm text-custom-gray-text font-bold duration-300 transform -translate-y-7 scale-75 top-2 z-10 origin-[0] bg-transparent px-2 peer-focus:px-2 peer-focus:text-custom-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                شماره تماس
              </label>
            </div>
            <div className="relative w-72">
              <textarea
                id="floating_outlined3"
                dir="rtl"
                className={`block px-2.5 w-full h-64 pb-2.5 pt-4 text-sm border-2 text-custom-blue font-bold bg-transparent rounded-lg appearance-none focus:outline-none focus:ring-0 focus:border-custom-blue peer ${
                  hasTouchedMessage && !message.trim()
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                placeholder=" "
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageBlur}
                disabled={loading}
              ></textarea>

              <label
                htmlFor="floating_outlined3"
                dir="rtl"
                className="absolute text-sm text-custom-gray-text font-bold duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-custom-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rounded-lg rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                متن درخواست ...
              </label>
            </div>
            <p className="mb-5 mx-4 text-xs sm:text-sm font-bold text-custom-gray-text text-center truncate">
              مشاورین ما در اولین فرصت با شما تماس خواهند گرفت
            </p>
          </div>
          {loading ? (
            <LoaderDots />
          ) : (
            <button
              type="submit"
              className={`ml-6 mb-6 bg-[rgba(0,82,204,1)] rounded-lg font-light h-10 text-gray-200 py-4 px-2 flex items-center justify-center ${
                !username.trim() || !number || !message || disable
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={
                !username.trim() || !number || !message || disable || loading
              }
            >
              ثبت درخواست
            </button>
          )}
        </form>
      )}
    </div>
  );
};

export default SabtDarkhast;
