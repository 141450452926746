import React from "react";
import line1 from "../pics/Others/line1.png";
import a1 from "../pics/Antens/a1.png";
import a2 from "../pics/Antens/a2.png";
import a3 from "../pics/Antens/a3.png";
import a4 from "../pics/Antens/a4.jpg";

const Anten = () => {
  const images1 = [a1, a2, a3, a4];

  return (
    <>
      <div className="relative mt-8  bg-custom-gray">
        <h2
          dir="rtl"
          className="absolute right-1 transform text-2xl font-bold text-black"
        >
          آنتن
        </h2>
        <img src={line1} alt="line" className="w-full mt-2" />
        <div className="overflow-x-auto rtl" style={{ direction: "rtl" }}>
          <div
            className="flex pb-5 justify-start"
            style={{ width: "calc(25% * 6)" }}
          >
            {images1.map((img, index) => (
              <div
                key={index}
                className="flex-shrink-0 px-2 py-3 flex items-center justify-center"
                style={{ width: "25%" }}
              >
                <img
                  src={img}
                  alt={`b${index + 1}`}
                  className="w-auto rounded-lg h-auto max-w-full h-full"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Anten;
