import React from "react";
import b1 from "../pics/Gire/g1.png";
import b2 from "../pics/Gire/g2.png";
import b3 from "../pics/Gire/g3.png";
import b4 from "../pics/Gire/g4.png";
const Girekamari = () => {
  const images1 = [b1, b2, b3, b4];
  return (
    <>
      <div className=" bg-custom-gradient  p-4">
        <h2 className="text-white font-bold text-2xl mb-5" dir="rtl">
          گیره کمری
        </h2>
        <div className="grid grid-cols-2 gap-4 pb-5">
          {images1.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`b${index + 1}`}
              className="w-full h-auto"
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Girekamari;
