import React, { useState } from "react";
import image1 from "../pics/Headphones/h1.jpg";
import image2 from "../pics/Headphones/h2.jpg";
import image3 from "../pics/Headphones/h3.jpg";
import image4 from "../pics/Headphones/h4.jpg";
import image5 from "../pics/Headphones/h5.jpg";

const Headphone = () => {
  const [currentImage, setCurrentImage] = useState(image1);
  const thumbnails = [image1, image2, image3, image4, image5];

  return (
    <div className="flex flex-col shadow-xl bg-custom-gray p-4 items-center">
      <h2 className="text-black font-bold text-2xl mb-4" dir="rtl">
        هدفون
      </h2>
      <div className="bg-transparent p-4 rounded-lg flex flex-col items-center">
        <img
          id="main"
          src={currentImage}
          alt="Main"
          className="w-full max-w-[400px] h-[400px] object-cover rounded-lg mb-4"
        />
        <div className="flex justify-center space-x-2">
          {thumbnails.map((img, index) => (
            <button key={index} onClick={() => setCurrentImage(img)}>
              <img
                src={img}
                alt={`Thumbnail ${index + 1}`}
                className={`w-16 h-16 rounded-md object-cover ${
                  currentImage === img ? "brightness-75" : ""
                }`}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Headphone;
