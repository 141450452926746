import React, { useEffect, useState } from "react";
import nocheck from "../pics/Others/nocheck.png";

const NoCheckBox = ({ isVisible }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (isVisible) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 1 : 100));
      }, 50); 

      return () => clearInterval(interval);
    }
  }, [isVisible]);

  return (
    <div
      className={`rounded-3xl mx-16 bg-white border-2 border-red-500 shadow-lg flex flex-col justify-center items-center gap-3 py-8 px-8 relative transition-opacity duration-500 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <img src={nocheck} alt="Check" className="h-16 w-16" />
      <p className="text-xs sm:text-sm font-bold text-custom-gray-text text-center truncate">
        مشکلی رخ داده{" "}
      </p>
      <p className="text-xs sm:text-sm font-bold text-black text-center truncate">
        لطفا دوباره تلاش کنید{" "}
      </p>
      <div className="w-full h-1 bg-gray-300 mt-4 relative">
        <div
          className="h-full bg-red-500"
          style={{ width: `${progress}%`, transition: "width 50ms linear" }}
        ></div>
      </div>
    </div>
  );
};

export default NoCheckBox;
