import React from "react";
import line1 from "../pics/Others/line1.png";
import b1 from "../pics/Batteries/b1.png";
import b2 from "../pics/Batteries/b2.png";
import b3 from "../pics/Batteries/b3.png";
import b4 from "../pics/Batteries/b4.png";
import b5 from "../pics/Batteries/b5.png";
import bg4 from "../pics/Others/bg-4.png";
import CallButton from "../tools/CallButton";
import iconcall from "../pics/Buttons/Iconcall.png";

const Batteries = () => {
  const images1 = [b1, b2, b3, b4, b5];
  return (
    <>
      <div className="relative mt-5">
        <h2
          dir="rtl"
          className="absolute right-1 transform text-2xl font-bold text-black"
        >
          باتری
        </h2>
        <img src={line1} alt="line" className="w-full mt-2" />
        <div className="overflow-x-auto rtl" style={{ direction: "rtl" }}>
          <div
            className="flex justify-start"
            style={{ width: "calc(25% * 6)" }}
          >
            {images1.map((img, index) => (
              <div
                key={index}
                className="flex-shrink-0 px-2 py-3"
                style={{ width: "25%" }}
              >
                <img
                  src={img}
                  alt={`b${index + 1}`}
                  className="w-full h-auto"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative flex justify-center items-center">
        <img src={bg4} alt="Background" className="w-full my-10" />
        <div className="absolute bottom-6 right-1">
          <CallButton
            children={
              <div
                dir="rtl"
                className="h-9 mr-10 rounded-lg flex items-center justify-center bg-[rgba(0,82,204,1)] shadow-custom"
              >
                <div className=" flex px-2 items-center gap-2">
                  <img src={iconcall} alt="phone" className="w-6 h-6 p-1" />
                  <button className="text-gray-200 font-light">
                    تماس با ما
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default Batteries;
